@charset "utf-8";

/* ----------------------------------------------------------------
   layout/footer
/  --------------------------------------------------------------*/

.l-footer {
  &-inner {
    @extend %contents-layout;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 20px;
  }
  &__logo {
    margin: 0;
    margin-right: 35px;
  }
  &__table {
        border-collapse: collapse;
    caption {
      text-align: left;
    }
    th {
      padding-right: 1em;
      font-weight: normal;
      text-align: left;
    }
  }

@include media(sp) {
  &-inner {
    display: block;
    padding: 20px 0;
  }
  &__logo {
    margin: 0;
    text-align: center;
  }
  &__table {
    margin: 0 auto;
  }
}
}

.copyright {
  padding: 5px 0;
  background: $mainColor;
  font-size: rem(13);
  text-align: center;
  line-height: 1;
  color: #fff;
}
