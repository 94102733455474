@charset "utf-8" ;

// ----------------------------------------------------------------
// function.scss - Event.scss
// -----------------------------------------------------------------

///
// media
// @param string
// @return media query and content
///
@mixin media($bp) {

  @if $bp == 'sp' {
    @media screen and (max-width: 767px) {
      @content;
    }
  }

  @else if $bp == 'tab' {
    @media screen and (max-width: 768px) {
      @content;
    }
  }

  @else if $bp == 'mid' {
    @media screen and (min-width: 1250px) {
      @content;
    }
  }

  @else if $bp == 'pc' {
    @media screen and (min-width: 768px) {
      @content;
    }
  }

}

$mainColor: #ff5400;

%contents-layout {
  width: 1080px;
  margin: 0 auto;
@include media(sp) {
  width: auto;
  margin: 0 15px;
}
@include media(tab) {
  width: auto;
  margin: 0 15px;
}
}


@mixin width( $parentPxWidth, $pxWidth ){
  width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

@mixin height($parentPxHeight, $pxHeight){
    height: floor((($pxHeight / $parentPxHeight) * 10000%)) / 100;
}

@mixin margin($parentPxWidth, $mTop, $mRight, $mBottom, $mLeft){
  margin : floor((($mTop / $parentPxWidth) * 10000%)) / 100 floor((($mRight / $parentPxWidth) * 10000%)) / 100 floor((($mBottom / $parentPxWidth) * 10000%)) / 100 floor((($mLeft / $parentPxWidth) * 10000%)) / 100;
}

@mixin padding($parentPxWidth, $pTop, $pRight, $pBottom, $pLeft){
  padding : floor((($pTop / $parentPxWidth) * 10000%)) / 100 floor((($pRight / $parentPxWidth) * 10000%)) / 100 floor((($pBottom / $parentPxWidth) * 10000%)) / 100 floor((($pLeft / $parentPxWidth) * 10000%)) / 100;
}

// left値
@mixin positionL($parentPxWidth, $pxLeft){
    left: floor((($pxLeft / $parentPxWidth) * 10000%)) / 100;
}
// top値
@mixin positionT($parentPxHeight, $pxTop){
    top: floor((($pxTop / $parentPxHeight) * 10000%)) / 100;
}
// right値
@mixin positionR($parentPxWidth, $pxRight){
    right: floor((($pxRight / $parentPxWidth) * 10000%)) / 100;
}
// bottom値
@mixin positionB($parentPxHeight, $pxBottom){
    bottom: floor((($pxBottom / $parentPxHeight) * 10000%)) / 100;
}
