@charset "utf-8";

/* ----------------------------------------------------------------
   component/catch
/  --------------------------------------------------------------*/
.catch {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 442px;
  margin-bottom: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  box-sizing: border-box;
  &__title {
    margin: 0;
    font-size: rem(35);
    text-shadow: 0px 0px 8px #004c77, 0px 0px 8px #004c77;
  }
  &__text {
    margin: 0;
    font-size: rem(24);
    text-shadow: 0px 0px 8px #004c77, 0px 0px 8px #004c77;
    text-align: center;
    line-height: 2.5;
  }

@include media(sp) {
  display: block;
  background-position: center;
  height: auto;
  &__title {
    font-size: rem(20);
  }
  &__text {
    font-size: rem(14);
    line-height: 1.6;
    //color: #000;
    //text-shadow: 0 0 0;
    text-align: left;
  }
}
}
