@charset "utf-8";
/* -----------------------------------------------------------
	header
----------------------------------------------------------- */
.l-header {
  @extend %contents-layout;
  padding: 15px 0 20px;
  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
  }
  &__logo {
    position: relative;
    margin: 0;
    line-height: 0;
  }
  &-description {
    flex: 1;
    margin: 10px 0 0;
    text-align: right;
    line-height: 1.8;
    p {
      margin: 0;
    }
    &__tel {
      color: $mainColor;
      font-size: rem(14);
      a {
        color: $mainColor;
        font-weight: bold;
      }
    }
  }
  &-nav {
    @extend %contents-layout;
    margin-bottom: 30px;
    &-list {
      display: flex;
      list-style: none;
      //margin: 0 10px;
      padding: 0;
      &__item {
        flex: 1;
        margin-right: 12px;
        &:last-child {
          margin: 0;
        }
        a {
          position: relative;
          display: block;
          width: 100%;
          @include padding(1080,15,15,15,15);
          padding-top: 15px;
          padding-bottom: 15px;
          text-align: center;
          font-weight: bold;
          font-size: rem(18);
          background: #ffc415;
          box-sizing: border-box;
          transition: .5s;
          &:hover {
            color: #fff;
            background: $mainColor;
          }
        }
      }
      &__item--active {
        @extend .l-header-nav-list__item;
        a {
          background: $mainColor;
          color: #fff;
        }
      }
    }
  }
}

/* 
	for sp
----------------------------------------------------------- */
@include media(sp) {
.l-header {
  position: relative;
  margin: 0;
  padding: 4.2em 10px 5px;
  &-inner {
    display: block;
  }
  &__logo {
    width: calc(256px/1.2);
    margin-bottom: 10px;
  }
  &-description {
    &__text {
      display: none;
    }
    &__tel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 5px 10px;
      font-size: rem(12);
      background: $mainColor;
      color: #fff;
      box-sizing: border-box;
      a {
        display: inline-block;
        color: #fff;
      }
    }
  }
  &-nav {
    //display: none;
    width: 100%;
    margin: 0;
    opacity: 0;
    transition: .3s;
    border: 10px solid $mainColor;
    box-sizing: border-box;
    &.open {
      //display: block;
      opacity: 1;
    }
    &-list {
      display: block;
      margin: 0;
      border: 5px solid #ffc415;
      &__item {
        margin-right: 0;
        border-bottom: 2px solid #ffc415;
        &:last-child {
          margin: 0;
          border: 0;
        }
        a {
          font-weight: normal;
          font-size: rem(16);
          background: #fff;
        }
      }
      &__item--active {
        a {
          background: #fff;
          color: #000;
        }
      }
    }
  }

}

.l-nav {
  //display: none;
  position: absolute;
  top: 20%;
  width: 100%;
  z-index: -1;
  &.open {
    z-index: 100;
  }
}

.menu-trigger {
  display: block;
  position: absolute;
  top: 70px;
  right: 15px;
  width: 30px;
  height: 19px;
  //transition: all .4s;
  box-sizing: border-box;
  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 8px;
    }
    &:nth-of-type(3) {
      top: 16px;
    }
    &.menu {
      background: none;
      height: auto;
      top: 20px;
      font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
      color: #636363;
      font-size: 10px;
    }
  }
  &.open {
    span:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
    .menu {
      display: none;
    }
  }

  
}


}