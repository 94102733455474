@charset "utf-8" ;

/* ----------------------------------------------------------------
   layout/content
/  --------------------------------------------------------------*/

.l-content {
  background: url(../img/share/bg.png);
@include media(sp) {
}
}

.l-content-inner {
  position: relative;
  //@extend %contents-layout;
}

.contents-wrap {
  @extend %contents-layout;
  padding-bottom: 80px;
@include media(sp) {
  padding-bottom: 40px;
}
}

.contents-inner {
  @extend %contents-layout;
}
