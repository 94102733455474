@charset "utf-8";

/* ----------------------------------------------------------------
   theme/recruit
/  --------------------------------------------------------------*/

.catch {
  &-recruit {
    @extend .catch;
    padding: 80px 0 70px;
    background-image: url(../img/recruit/bg_catch.png);
    &__title {
      @extend .catch__title;
      margin-bottom: 20px;
    }
    &__text {
      @extend .catch__text;
    }
    &-icon {
      position: absolute;
      left: 40px;
      bottom: 40px;
      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 152px;
        height: 134px;
        margin: 0 0 20px;
        font-size: rem(18);
        font-weight: bold;
        color: #000;
        background: url(../img/recruit/baloon.png) no-repeat;
        span {
          display: inline-block;
          width: 5em;
          margin-top: 1em;
        }
      }
      &__img {
        margin-left: 15px;
        //animation: yurayura 4s linear infinite;
      }
    }
  }
@include media(tab) {
  &-recruit {
    &-icon {
      position: absolute;
      left: 20px;
      bottom: 20px;
      &__text {
        width: 122px;
        height: 104px;
        font-size: rem(14);
        background-size: contain;
        span {
          display: inline-block;
          width: 5em;
          margin-top: 1em;
        }
      }
      &__img {
        margin-left: 15px;
        @include width(250,166);
      }
    }
  }
}
@include media(sp) {
  &-recruit {
    padding: 30px 15px 120px;
    margin: -20px -15px 20px;
    box-sizing: content-box;
    &-icon {
      display: flex;
      align-items: flex-end;
      transform: scale(0.6);
      bottom: 0;
      left: auto;
      right: -40px;
      &__text {
        margin: 0;
      }
      &__img {
        margin: 0;
      }
    }
  }

}
}

.recruit {
  &-contents {
    margin: 0 10px 40px;
    padding: 35px;
    background: #fff;
    border: 2px solid $mainColor;
    border-radius: 18px;
  }
  &-table {
    width: 100%;
    margin: 0 0 30px;
    border-collapse: collapse;
    border-spacing: 0;
    th,td {
      padding: 10px 0;
      border-bottom: 1px solid #ababab;
    }
    th {
      width: 150px;
      span {
        display: block;
        padding: 15px;
        background: $mainColor;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
      }
    }
    td {
      padding-left: 30px;
    }
  }
  &-btn {
    display: flex;
    justify-content: space-between;
    .btn {
      width: 48%;
      margin: 0 0 10px;
      a {
        width: auto;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 0;
        font-size: rem(26);
      }
      &__link--contact {
        &:before {
          display: inline-block;
          content: " ";
          width: 32px;
          height: 20px;
          margin-right: 15px;
          background: url(../img/share/icon_mail.png) no-repeat;
        }
      }
    }
  }
  &-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 605px;
    margin: 0;
    background: url(../img/recruit/img_recruit.jpg) center top no-repeat;
    //background-size: contain;
    font-size: rem(36);
    font-weight: bold;
    color: #fff;
    text-shadow: 0px 0px 8px #004c77, 0px 0px 8px #004c77;
  }
@include media(sp) {
  &-contents {
    margin: 0 0 30px;
    padding: 15px;
  }
  &-table {
    margin-bottom: 20px;
    th {
      width: 6em;
      vertical-align: top;
      span {
        padding: 5px 10px;
      }
    }
    td {
      padding-left: 15px;
    }
  }
  &-btn {
    display: block;
    .btn {
      width: auto;
      a {
        font-size: rem(20);
      }
    }
  }
  &-message {
    margin: 0 -15px;
    height: 180px;
    background-size: contain;
    font-size: rem(20);
    text-align: center;
  }
}
}