@charset "utf-8" ;

/* ----------------------------------------------------------------
    core/base
/  --------------------------------------------------------------*/

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body {
  color: #000;
  //font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
  //font-family: 'Noto Sans Japanese', Sans-Serif;
  font-family: "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.6;
  font-size: 16px;
@include media(sp) {
  //font-weight: normal;
  //font-family: 'Noto Sans Japanese', Sans-Serif;
  font-size: 14px;
}
}

a {
  color: #000;
  text-decoration: none;
  &:hover {
    //opacity: .9;
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 1em;
}

[data-show="sp"] {
  @include media(pc) {
    display: none;
  }
}

dl,
ul,
ol {
  margin-top: 0;
}

[data-show="sp"] {
  @include media(pc) {
    display: none;
  }
}

[data-show="pc"] {
  @include media(sp) {
    display: none;
  }
}
