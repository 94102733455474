@charset "utf-8";

/* ----------------------------------------------------------------
   theme/price
/  --------------------------------------------------------------*/

.-effect {
  transition: .8s;
  transform: translate(0,60px);
  opacity: 0;
  &.-on {
    opacity: 1;
    transform: translate(0,0);
  }
}

.price {
  &-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 15px 40px;
    padding-bottom: 40px;
    background: url(../img/price/line.png) center bottom no-repeat;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      background: none;
    }
    &__title {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 10px;
      padding-left: 20px;
      padding-bottom: 5px;
      border-bottom: 2px solid $mainColor;
      font-size: rem(20);
      box-sizing: border-box;
      &:before {
        flex: 0 0 22px;
        display: inline-block;
        content: " ";
        width: 22px;
        height: 22px;
        //margin-top: -5px;
        margin-right: 10px;
        background: url(../img/price/icon_title.png) no-repeat;
        vertical-align: middle;
      }
    }
    &-img {
      width: 620px;
      &__main {
        margin-bottom: 40px;
        padding-top: 40px;
        text-align: center;
      }
      &-thumb {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          transition: .3s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    &-detail {
      width: 430px;
      &__table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border: 2px solid #ffc415;
        th,td {
          display: block;
          padding: 10px;
          text-align: center;
        }
        th {
          background: #ffc415;
        }
        td {
          background: #fff;
        }
      }
    }
  }
@include media(tab) {
  &-section {
    justify-content: space-between;
    &-img {
      @include width(1080,620);
    }
    &-detail {
      @include width(1080,430);
    }
  }
}
@include media(sp) {
  &-section {
    //display: block;
    margin: 0 0 20px;
    &__title {
      //align-items: flex-start;
      font-size: rem(18);
    }
    &-img {
      width: auto;
      margin-bottom: 10px;
      &__main {
        margin-bottom: 20px;
        padding-top: 20px;
      }
    }
    &-detail {
      width: 100%;
    }
  }
}
}