@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top
/  --------------------------------------------------------------*/

.top {
  &-message {
    padding: 100px 0;
    background: url(../img/top/bg_message.jpg) center top no-repeat;
    background-size: cover;
    color: #fff;
    font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
    font-weight: bold;
    &-inner {
      width: 680px;
      margin: 0 auto;
    }
    &__title {
      margin: 0 0 30px;
      padding-bottom: 20px;
      font-size: rem(30);
      line-height: 1;
      border-bottom: 2px solid #fff;
    }
    &__text {
      line-height: 2.8;
    }
    &__sign {
      margin: 0;
      text-align: right;
    }
  }
@include media(sp) {
  &-message {
    padding: 50px 0;
    &-inner {
      width: auto;
      margin: 0 15px;
    }
    &__title {
      margin: 0 0 20px;
      padding-bottom: 10px;
      font-size: rem(20);
    }
    &__text {
      line-height: 1.6;
    }
  }
}

  &-introduction {
    &-title {
      width: 234px;
      height: 264px;
      margin: 0 auto 40px;
      font-size: 17px;
      font-weight: normal;
      background-image: url(../img/top/img_intro.png), url(../img/top/bg_balloon.png);
      background-repeat: no-repeat;
      background-position: center 123px, center center;
      &__text {
        display: block;
        width: 7em;
        margin: 0 auto;
        padding-top: 35px;
        text-align: center;
      }
    }
    &-list {
      list-style: none;
      margin: 0 0 50px;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        flex: 0 0 264px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &__text {
          display: block;
          margin-top: 10px;
          padding: 0 1em;
        }
      }
    }
  }
@include media(tab) {
  &-introduction {
    &-list {
      justify-content: center;
      &-item {
        margin-bottom: 15px;
        &:nth-child(odd) {
          margin-right: 15px;
        }
      }
    }
  }
}
@include media(sp) {
  &-introduction {
    &-list {
      display: block;
      &-item {
        margin-bottom: 1em;
      }
    }
  }
}
  &-map {
    margin-bottom: 50px;
    &-title {
      margin: 0 0 25px;
      text-align: center;
      font-size: rem(24);
      &__text {
        display: inline-block;
        &:before {
          display: inline-block;
          content: " ";
          width: 45px;
          height: 35px;
          margin-right: 30px;
          background: url(../img/top/icon_map_title.png) no-repeat;
          vertical-align: top;
        }
      }
    }
    &-img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 15px 30px;
      padding: 20px 30px;
      padding-left: 80px;
      background: #fff;
      border-top: 2px solid $mainColor;
      box-sizing: border-box;
      &__img {
        width: 507px;
      }
      &__pamphlet {
        width: 252px;
        text-align: center;
        a {
          display: inline-block;
          text-decoration: underline;
          &:before {
            content: ">>";
          }
        }
      }
    }
    &-list {
      list-style: none;
      margin: 0 30px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        line-height: 0;
        margin-bottom: 20px;
        transition: .8s;
        transform: scale(0, 0);
        &.-on {
          transform: scale(1, 1);
        }
      }
    }
  }
@include media(sp) {
  &-map {
    &-title {
      margin-bottom: 10px;
      font-size: rem(20);
    }
    &-img {
      display: block;
      margin: 0 0 20px;
      padding: 15px;
      &__img {
        width: auto;
      }
      &__pamphlet {
        width: auto;
      }
    }
    &-list {
      margin: 0;
      li {
        width: 48%;
        margin-bottom: 10px;
      }
    }
  }
}

  &-bnr-course {
    position: relative;
    @extend %contents-layout;
    margin-bottom: 35px;
    background: url(../img/top/bg_bnr.png) no-repeat;
    box-sizing: border-box;
    &-detail {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;
      padding-bottom: 60px;
      &:hover {
        opacity: 1;
        .top-bnr-course__img {
          transform: rotate(10deg);
        }
        .top-bnr-course__btn span {
          bottom: -7px;
          box-shadow: 0 0 0;
        }
      }
    }
    &__title {
      margin: 30px 0 30px;
      font-size: rem(36);
      color: #fff;
      text-shadow: 0 0 24px #c11b00;
      span {
        font-size: rem(30);
      }
    }
    &__text {
      position: absolute;
      top: 15px;
      right: 75px;
      font-size: rem(24);
      font-weight: bold;
      line-height: 1.2;
      z-index: 1;
      span {
        font-size: rem(18);
        font-weight: normal;
      }
      em {
        display: inline-block;
        padding-bottom: 5px;
        font-style: normal;
        font-size: rem(36);
        background: url(../img/top/wave.png) left bottom no-repeat;
      }
    }
    &__img {
      position: absolute;
      right: 30px;
      bottom: 0;
      z-index: 0;
    }
    &__btn {
      display: inline-block;
      margin: 0;
      span {
        position: relative;
        display: inline-block;
        padding: 25px 60px;
        border-radius: 20px;
        background: #fff;
        text-align: center;
        font-size: rem(24);
        font-weight: bold;
        line-height: 1;
        box-shadow: 0px 7px 0px 0px #ffa77c;
        box-sizing: border-box;
        &:before {
          display: inline-block;
          content: " ";
          width: 33px;
          height: 24px;
          margin-right: 20px;
          margin-top: -10px;
          background: url(../img/top/icon_camera.png) no-repeat;
          vertical-align: middle;
        }
      }
    }
  }
@include media(tab) {
  &-bnr-course {
    &__title {
      font-size: rem(30);
      span {
        font-size: rem(26);
      }
    }
    &__text {
      right: 15px;
      span {
        font-size: rem(18);
      }
      em {
        font-size: rem(30);
      }
    }
    &__img {
      right: 15px;
      bottom: 15px;
      @include width(1080,351);
    }
  }
}
@include media(sp) {
  &-bnr-course {
    margin: 0 -15px 20px;
    padding: 0 15px;
    background-size: cover;
    background-position: 15% top;
    box-sizing: content-box;
    &-detail {
      width: 100%;
      padding-bottom: 20px
    }
    &__title {
      margin: 10px 0;
      font-size: rem(22);
      text-align: center;
      span {
        font-size: rem(16);
      }
    }
    &-badge {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }
    &__text {
      position: static;
      font-size: rem(16);
      margin-left: 15px;
      span {
        font-size: rem(14);
      }
      em {
        font-size: rem(22);
      }
    }
    &__img {
      //position: static;
      //transform: scale(.5);
      width: 120px;
      right: 10px;
    }
    &__btn {
      span {
        padding: 25px 15px;
        font-size: rem(20);
        border-radius: 15px;
        &:before {
          margin-right: 10px;
          transform: scale(.8);
        }
      }
    }
  }
}

  &-bnr-app {
    position: relative;
    @extend %contents-layout;
    display: flex;
    //flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    padding: 30px 75px 40px;
    background-color: #fff;
    background-image: url(../img/top/check.png), url(../img/top/img_taxi.png);
    background-repeat: no-repeat;
    background-position: left top, 97% center;
    border: 6px solid #ffc415;
    box-sizing: border-box;
    &__badge {
      margin-right: 15px;
    }
    &-text {
      img + img {
        margin-left: 5px;
      }
    }
    &-link {
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 48%;
      bottom: 10px;
      line-height: 0;
      right: 0;
      li {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
@include media(sp) {
  &-bnr-app {
    display: block;
    margin: 0;
    padding: 10px;
    padding-top: 30px;
    background-size: 50px auto, 65px auto;
    &__badge {
      position: absolute;
      right: -10px;
      top: 5px;
      width: 50px;
    }
    &-text {
      margin-top: 20px;
      padding: 5px 5px 0;
      img:first-child {
        width: 160px;
      }
    }
    &-link {
      position: static;
      width: auto;
      display: flex;
      justify-content: center;
      li {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
}

.main-visual {
  @extend %contents-layout;
@include media(sp) {
  margin: 0;
  line-height: 0;
}
}

.btn {
  margin-bottom: 70px;
  a,
  &__link {
    position: relative;
    display: block;
    width: 70%;
    margin: 0 auto;
    padding: 36px;
    border-radius: 20px;
    background: $mainColor;
    color: #fff;
    text-align: center;
    font-size: rem(24);
    font-weight: bold;
    line-height: 1;
    border: 0;
    box-shadow: 0px 7px 0px 0px #c4bbb6;
    box-sizing: border-box;
    &:hover {
      bottom: -7px;
      opacity: 1;
      box-shadow: 0 0 0;
      cursor: pointer;
    }
  }
@include media(sp) {
  a {
    width: auto;
    padding: 25px;
    font-size: rem(20);
  }
}
}