@charset "utf-8";

/* ----------------------------------------------------------------
   layout/back2top
/  --------------------------------------------------------------*/

.back2top {
  position: fixed;
  right: 10%;
  a {
    display: block;
    padding-bottom: 22px;
    background: url(../img/share/icon_top.png) center bottom no-repeat;
    font-size: rem(26);
    font-weight: bold;
    text-align: center;
    color: $mainColor;
    line-height: 1;
  }
@include media(sp) {
  display: none;
}
}
