@charset "utf-8";

/* ----------------------------------------------------------------
   theme/company
/  --------------------------------------------------------------*/


.company {
  &-table {
    width: 100%;
    width: calc(100% - 420px);
    margin-right: 40px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #cdc9c9;
    border-bottom: 0;
    th,td {
      padding: 30px;
      border-bottom: 1px solid #cdc9c9;
    }
    th {
      padding: 30px 60px;
      background: $mainColor;
      color: #fff;
      font-size: rem(18);
    }
    td {
      background: #fff;
    }
  }
  &-contents {
    display: flex;
    justify-content: space-between;
  }
  &-map {
    .gmap {
      position: relative;
      width: 400px;
      height: 476px;
      overflow: hidden;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
@include media(tab) {
  &-table {
    width: 48%;
    margin-right: 0;
    th,td {
      padding: 20px;
    }
  }
  &-map {
    @include width(1080,400);
    width: 50%;
    .gmap {
      width: 100%;
      height: 300px;
    }
  }
}
@include media(sp) {
  &-table {
    width: 100%;
    margin-bottom: 20px;
    th,td {
      padding: 15px;
    }
    th {
      width: 4em;
      font-size: rem(14);
    }
  }
  &-contents {
    display: block;
  }
  &-map {
    width: 100%;
    .gmap {
      width: 100%;
      height: 300px;
    }
  }
}
}
