@charset "utf-8";

/* ----------------------------------------------------------------
   component/ttl
/  --------------------------------------------------------------*/

.page-title {
  margin: 0 0 35px;
  padding: 18px 30px;
  background: $mainColor;
  color: #fff;
  line-height: 1;
  font-size: rem(20);

@include media(sp) {
  padding: 15px 20px;
}
}
