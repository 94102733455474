@charset "utf-8";

/*----------------------------------------------------
  lib
----------------------------------------------------*/
@import "scss/lib/normalize";

/*----------------------------------------------------
  core
----------------------------------------------------*/
@import "scss/core/function";
@import "scss/core/mixin";
@import "scss/core/base";

/*----------------------------------------------------
  layout
----------------------------------------------------*/
@import "scss/layout/content";
@import "scss/layout/header";
@import "scss/layout/main";
@import "scss/layout/back2top";
@import "scss/layout/footer";

/*----------------------------------------------------
  component
----------------------------------------------------*/
@import "scss/component/ttl";
@import "scss/component/menu";
@import "scss/component/catch";

/*----------------------------------------------------
  theme
----------------------------------------------------*/
@import "scss/theme/normalize";
@import "scss/theme/top";
@import "scss/theme/price";
@import "scss/theme/course";
@import "scss/theme/company";
@import "scss/theme/recruit";
@import "scss/theme/contact";

/*----------------------------------------------------
  utilities
----------------------------------------------------*/
@import "scss/utility/text";
