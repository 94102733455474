@charset "utf-8";

/* ----------------------------------------------------------------
   theme/course
/  --------------------------------------------------------------*/

.catch {
  &-course {
    @extend .catch;
    position: relative;
    padding: 80px 0 70px;
    background-image: url(../img/course/bg_catch.png);
    background-position: center;
    &:before {
      position: absolute;
      left: 20px;
      //top: 0;
      display: block;
      content: " ";
      width: 241px;
      @include width(1080,241);
      height: 389px;
      background: url(../img/course/img_catch.png) left center no-repeat;
      background-size: contain;
    }
    &__title {
      @extend .catch__title;
      margin-bottom: 20px;
    }
    &__text {
      @extend .catch__text;
    }
    &-icon {
      display: flex;
      align-items: flex-end;
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 314px;
      height: 265px;
      //@include width(1080,314);
      background: url(../img/course/img_miyako.png) center center no-repeat;
      background-size: contain;
      &__text {
        position: relative;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 152px;
        width: 152px;
        @include width(314,152);
        height: 134px;
        margin: 0 0 20px;
        font-size: rem(14);
        font-weight: bold;
        color: #000;
        background: url(../img/recruit/baloon.png) no-repeat;
        span {
          display: inline-block;
          margin-top: 1em;
        }
      }
      &__img {
        margin-top: 15px;
        @include width(314,166);
      }
    }
  }
@include media(tab) {
  &-course {
    &:before {
      background-position: left bottom;
    }
    &__title {
      position: relative;
      z-index: 99;
    }
    &__text {
      position: relative;
      z-index: 99;
    }
    &-icon {
      right: 20px;
      bottom: 20px;
      background-size: contain;
      @include width(1080,314);
      &__text {
        bottom: 20px;
        flex: 0 0 132px;
        width: 100px;
        height: 114px;
        margin-right: -20px;
        font-size: rem(12);
        background-size: contain;
      }
    }
  }
}
@include media(sp) {
  &-course {
    padding: 30px 15px 100px;
    margin: -20px -15px 20px;
    box-sizing: content-box;
    &:before {
      @include height(442,389);
      background-position: left center;
      top: 0;
      left: 10px;
    }
    &__description,
    &__title,
    &__text {
      text-align: center;
    }
    &-icon {
      display: flex;
      align-items: flex-end;
      width: auto;
      transform: scale(0.6);
      bottom: -40px;
      left: auto;
      right: -30px;
      background-size: 230px auto;
      background-position: 70% bottom;
      &__text {
        margin: 0;
        width: auto;
      }
      &__img {
        margin: 0;
      }
    }
  }

}
}

.course {
  &-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 25px 30px;
    border: 2px solid #ffc415;
    background: #fff;
    &__title {
      width: 100%;
    }
    &__pamphlet {
      margin-right: 20px;
      text-align: center;
      a {
        display: inline-block;
        text-decoration: underline;
        &:before {
          content: ">>";
        }
      }
    }
  }
@include media(tab) {
  &-map {
    align-items: center;
    &__img {
      @include width(1020,645);
    }
    &__pamphlet {
      @include width(1020,289);
    }
  }
}
@include media(sp) {
  &-map {
    display: block;
    &__img {
      width: auto;
    }
    &__pamphlet {
      width: auto;
      margin: 0;
    }
  }
}
  &-nav {
    list-style: none;
    margin: 0 0 40px;
    padding: 0 0 50px;
    display: flex;
    justify-content: center;
    background: url(../img/course/nav_arrow.png) center bottom no-repeat;
    li {
      width: 22%;
    }
    li + li {
      margin-left: 7px;
    }
    &__link {
      display: block;
      padding: 10px;
      background: #ffc415;
      font-weight: bold;
      text-align: center;
      &--active {
        @extend .course-nav__link;
        color: #fff;
        background: $mainColor;
      }
    }
  }
@include media(sp) {
  &-nav {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    li {
      width: 48.5%;
      margin: 0 0 10px !important;
    }
  }
}


  &-section {
    margin: 0 30px 90px;
    &:last-child {
      margin-bottom: 60px;
    }
    &-header {
      display: flex;
      align-items: center;
      margin: 0 -15px 30px;
      padding-bottom: 15px;
      background: url(../img/course/line.png) left bottom no-repeat;
      &__title {
        margin: 0;
        margin-right: 20px;
        padding-left: 150px;
        font-size: rem(24);
        background: url(../img/course/img_title.png) left center no-repeat;
        span {
          font-size: rem(40);
          color: $mainColor;
        }
      }
      &__price-title {
        margin: 0;
        margin-right: 20px;
        padding: 10px 30px;
        font-size: rem(20);
        color: #fff;
        font-weight: normal;
        background: $mainColor;
        line-height: 1;
      }
      &__price-text {
        margin: 0;
        font-size: rem(20);
        font-weight: bold;
      }
    }
    &-detail {
      display: flex;
    }
    &-item {
      //width: 30%;
      margin-right: 32px;
      &:last-child {
        margin: 0;
      }
      &__title {
        margin: 0 0 20px;
        color: $mainColor;
        font-size: rem(20);
        line-height: 1;
        &:before {
          flex: 0 0 22px;
          display: inline-block;
          content: " ";
          width: 22px;
          height: 22px;
          margin-top: -5px;
          margin-right: 10px;
          background: url(../img/price/icon_title.png) no-repeat;
          vertical-align: middle;
        }
      }
      &-list {
        list-style: none;
        margin: 0;
        padding: 0;
        border-left: 2px solid #ffc415;
        border-right: 2px solid #ffc415;
        li {
          margin: 0;
          padding: 10px 0 0;
          background: #ffc415;
          text-align: center;
          font-weight: bold;
          &:after {
            display: block;
            content: " ";
            width: 100%;
            height: 40px;
            margin-top: 10px;
            background: #fff url(../img/course/arrow.png) center center no-repeat;
          }
          img {
            display: block;
            margin-top: 10px;
            margin-bottom: -10px;
          }
          &:last-child {
            padding-bottom: 10px;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
@include media(sp) {
  &-section {
    margin: 0 0 40px;
    &-header {
      flex-wrap: wrap;
      margin: 0 0 30px;
      &__title {
        width: calc(100% - 120px);
        margin-bottom: 20px;
        padding-left: 120px;
        background-size: 100px auto;
        font-size: rem(20);
        span {
          font-size: rem(30);
        }
      }
      &__price-title {
        margin: 0 10px 10px 0;
        padding: 10px 20px;
        font-size: rem(14);
      }
      &__price-text {
        flex: 0 0 55%;
        font-size: rem(14);
      }
    }
    &-detail {
      display: block;
    }
    &-item {
      max-width: 310px;
      margin: 0 auto 30px;
      &:last-child {
        margin: 0 auto;
      }
    }
  }
}

  &-att {
    margin: 0 15px 60px;
    padding: 30px 50px;
    padding-left: 70px;
    background: #e8e8e8;
  }
  
@include media(sp) {
  &-att {
    margin: 0 0 30px;
    padding: 15px;
    padding-left: 35px;
  }
}

  &-btn {
    @extend .btn;
    width: calc(100% - 30px);
    margin: 0 15px;
    a {
      width: auto;
      box-shadow: 0 0 0;
      font-size: rem(26);
      font-weight: normal;
      line-height: 1.6;
      box-sizing: border-box;
      span {
        font-weight: bold;
      }
    }
  }
@include media(sp) {
  &-btn {
    width: 100%;
    margin: 0;
    a {
      font-size: rem(16);
      span {
        font-size: rem(24);
      }
    }
  }
}
}