@charset "utf-8";

/* ----------------------------------------------------------------
   theme/contact
/  --------------------------------------------------------------*/

.contact {
  &-contents {
    margin: 0 10px 40px;
    padding: 35px;
    background: #fff;
    border: 2px solid $mainColor;
    border-radius: 18px;
  }
  &-table {
    width: 100%;
    margin: 0 0 30px;
    border-collapse: collapse;
    border-spacing: 0;
    th,td {
      padding: 10px 0;
      border-bottom: 1px solid #ababab;
    }
    th {
      width: 150px;
      vertical-align: top;
      span {
        display: block;
        padding: 15px;
        background: $mainColor;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
      }
    }
    td {
      padding-left: 30px;
    }
  }
@include media(sp) {
  &-contents {
    margin: 0 0 30px;
    padding: 15px;
    .btn {
      &__link {
        padding: 20px;
      }
    }
  }
  &-table {
    margin-bottom: 20px;
    th,td {
      display: block;
    }
    th {
      width: 100%;
      vertical-align: top;
      border-bottom: 0;
      padding-bottom: 0;
      span {
        padding: 5px 10px;
      }
    }
    td {
      padding-left: 0;
    }
  }
}
}

.form-item-text,
.form-item-textarea {
  width: 100%;
  box-sizing: border-box;
}

.form-item-text {
  height: 2.8em;
}

.form-item-textarea {
@include media(sp) {
  height: 10em;
}
}
